<template>
  <Layout>
    <div style="margin-bottom: 150px">
      <div class="container-fluid pt-5">
        <div class="title mb-4">
          Choose Application
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <BootStrapAlert/>
          </div>

          <div v-show="this.applications.length=== 0" class="col-md-12 col-lg-12">
            <div class="application-container">
              <div class="notify-text">
                <div>
                  <div class="text0 p-5">
                    You didn't apply any applications yet.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="this.applications.length !== 0" v-for="(item, index) in this.applications"
               @click="this.gotoQuestion(item.id, item.answered)" :key="index" class="col-md-6 col-lg-6">
            <div class="application-container">
              <div class="application-item">
                <div>
                  <div class="text1">
                    <font-awesome-icon icon="fa-solid fa-home"/>&nbsp;
                    <span v-html="item.company_name"></span>
                  </div>
                  <div class="text2">
                    <font-awesome-icon icon="fa-solid fa-dollar"/>
                    {{ item.total_estimate }}
                  </div>

                  <div class="dropdown-divider"></div>
                  <div class="text2">
                    <font-awesome-icon icon="fa-solid fa-user"/>
                    {{ item.person_name }}
                  </div>
                  <div class="text2">
                    <font-awesome-icon icon="fa-solid fa-envelope"/>
                    {{ item.person_email }}
                  </div>
                  <div class="text2">
                    <font-awesome-icon icon="fa-solid fa-phone"/>
                    {{ this.presentPhoneNumber(item.mobile_no) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="offset-md-3 col-md-6 text-center mt-3">
            <button class="submit">
              <RouterLink to="/new-application">
                Start Application
              </RouterLink>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import BootStrapAlert from "@/components/utils/Alert";

// import {mapGetters, mapActions} from "vuex";
import loanApi from "@/apis/loan-api"
// import VueGoogleAutocomplete from "vue-google-autocomplete";
// import Constants from "@/utils/constants";
import GlobalFunc from "@/utils/functions";

import {
  authMethods,
} from "@/state/helpers";

export default {
  name: "applications",
  data() {
    return {
      applications: []
    }
  },
  components: {
    Layout,
    BootStrapAlert,
  },
  methods: {
    ...authMethods,
    presentPhoneNumber: GlobalFunc.presentPhoneNumber,
    async getApplications() {
      // this.$isLoading(true);
      await loanApi.getApplications().then((response) => {
        const {status, data} = response;
        if (status === "success") {
          const {applications} = data;
          this.applications = applications;
        }
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          // Handle 400 error
          const {message} = error.response.data;
          if (message === "JWT Expired") {
            this.logOut();
            this.$router.push({
              name: "login"
            });
          } else
            alert(message);
        } else {
          // Handle other errors
          console.log("An error occurred:", error.message);
        }

      }).finally(() => {
        // this.$isLoading(false);
      });
    },
    gotoQuestion(application_id, answered) {
      // if (answered === 1) {
      //   this.$router.push({
      //     path: `/application/${application_id}/documents`
      //   });
      //   return;
      // }
      console.log(answered)

      this.$router.push({
        path: `/application/${application_id}/questionnaire`
      })
    }
  },
  mounted() {
    this.getApplications();
  }
}
</script>

<style scoped lang="scss" src="./applicatins.scss"></style>
<template>
  <div style="font-size: 12pt" class="alert fade show alert-dismissible fade show"
       v-if="message && this.aflag === this.flag" :class="'alert-' + color" role="alert">
    {{ message }}
    <ul v-if="errors">
      <!-- <li v-for="(value, key) in errors">
          {{ value[0] }}
      </li> -->
    </ul>
    <button type="button" class="btn-close" aria-label="Close" @click="this.resetState"></button>
  </div>
  <div style="font-size: 12pt" class="alert fade show alert-dismissible fade show"
       v-else-if="message && this.flag===''" :class="'alert-' + color" role="alert">
    {{ message }}
    <ul v-if="errors">
      <!-- <li v-for="(value, key) in errors">
          {{ value[0] }}
      </li> -->
    </ul>
    <button type="button" class="btn-close" aria-label="Close" @click="this.resetState"></button>
  </div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'

export default {
  name: "Alert",
  props: {
    aflag: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      // Code...
    }
  },
  computed: {
    ...mapGetters('alert', ['message', 'errors', 'color', 'flag'])
  },
  created() {
    // Code...
  },
  unmounted() {
    this.resetState()
  },
  mounted() {

  },
  watch: {
    "$route.params.id": function() {
      this.resetState()
    }
  },
  methods: {
    ...mapActions('alert', ['resetState'])
  }
}
</script>

<style scoped>

</style>